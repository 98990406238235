import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"

import ClientCard from "../../../components/ClientCard"
import Info from "../../../components/Info"
import Inner from "../../../components/Inner"

import { tablet, desktop } from "../../../utils/breakpoint"

const Client = styled.section``

const Content = styled.div`
	padding: 4em 0;
`

const TopBox = styled.div`
	${tw`mb-8`}
`

const List = styled.div`
	display: flex;
	flex-wrap: wrap;

	${ClientCard} {
		margin-right: 16px;
		margin-bottom: 16px;
	}

	@media ${tablet} {
		width: 80%;
	}

	@media ${desktop} {
		width: 60%;
	}
`

const BottomBox = styled.div``

const ClientSection = () => {
	const data = useStaticQuery(graphql`
		{
			allStrapiCompanyClients {
				edges {
					node {
						name
						logo {
							publicURL
						}
					}
				}
			}

			strapiHomePage {
				clients {
					head
					detail
					caption
				}
			}
		}
	`)

	const clients = data.allStrapiCompanyClients.edges
	const section = data.strapiHomePage.clients

	return (
		<Client>
			<Inner>
				<Content>
					<TopBox>
						<Info subtitle={section.caption} title={section.head} detail={section.detail} />
					</TopBox>

					<BottomBox>
						<List>
							{clients.map((item, index) => (
								<ClientCard key={index} imageUrl={item.node.logo.publicURL} name={item.node.name} />
							))}
						</List>
					</BottomBox>
				</Content>
			</Inner>
		</Client>
	)
}

export default ClientSection
