import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import tw from "twin.macro"
import styled from "@emotion/styled"

import Inner from "../../../components/Inner"
import Button from "../../../components/Button"

import { mobile } from "../../../utils/breakpoint"
import * as Color from "../../../utils/color"

const ButtonContainer = styled.div`
	display: grid;
	grid-gap: 16px;
	grid-template-areas: "a b";
	grid-auto-columns: 200px;

	@media ${mobile} {
		grid-auto-columns: 1fr;
		grid-template-areas: "a" "b";

		${Button} {
			width: 100%;
		}
	}
`

const HeroImage = styled.img`
	max-height: calc(62vh);
	width: 100%;
	max-width: 100%;
	height: auto;

	object-fit: contain;
	justify-self: flex-end;
	align-self: center;
`

const Hero = styled.section``

const LeftBox = styled.div``

const RightBox = styled.div``

const Content = styled.div`
	min-height: calc(100vh - 125px);
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-areas: "left right";

	${LeftBox} {
		grid-area: left;
	}

	${RightBox} {
		grid-area: right;
		display: inherit;
	}

	@media ${mobile} {
		grid-template-areas: "right" "left";
		grid-template-rows: auto auto;
	}
`

const Info = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const Subtitle = styled.p`
	${tw`text-xs md:text-sm lg:text-sm tracking-widest pb-2`}

	color: ${Color.secondary};
`

const Heading = styled.h1`
	${tw`text-5xl md:text-6xl lg:text-6xl mb-3`}
	font-weight: bolder;
	line-height: 1em;

	color: ${Color.primary};
`

const Detail = styled.p`
	${tw`text-base md:text-lg mb-6`}

	@media ${mobile} {
		width: 100%;
	}

	color: ${Color.primary};
`

const Dot = styled.span`
	color: ${Color.secondary};
`

const HeroSection = () => {
	const data = useStaticQuery(graphql`
		{
			strapiHomePage {
				hero {
					caption
					detail
					head
					image {
						publicURL
					}
				}
			}
		}
	`)

	const section = data.strapiHomePage.hero

	return (
		<Hero>
			<Inner>
				<Content>
					<LeftBox>
						<Info>
							<Subtitle>{section.caption}</Subtitle>
							<Heading>
								Arrow
								<Dot>dot</Dot>
							</Heading>
							<Detail>{section.detail}</Detail>
							<ButtonContainer>
								<Button outline onClick={() => navigate("/products")}>
									View Products
								</Button>
								<Button onClick={() => navigate("/news")} aria-label={"read news"}>
									Read News
								</Button>
							</ButtonContainer>
						</Info>
					</LeftBox>
					<RightBox>
						<HeroImage src={section.image.publicURL} />
					</RightBox>
				</Content>
			</Inner>
		</Hero>
	)
}

export default HeroSection
