import React from "react"
import styled from "@emotion/styled"

const Image = styled.img`
	border-radius: 8px;
`

const Client = styled.div`
	width: 99px;
	height: 99px;
	border: solid 1px lightgray;
	border-radius: 8px;

	display: flex;
	justify-content: center;
	align-items: center;
`

const ClientCard = ({ name, imageUrl, imageAlt, ...props }) => {
	return (
		<Client {...props}>
			<Image src={imageUrl} alt={name} />
		</Client>
	)
}

export default styled(ClientCard)``
