import React from "react"
import Hero from "../sections/home/hero"
import Solutions from "../sections/home/solutions"
import Clients from "../sections/home/clients"

export default function Home() {
	return (
		<>
			<Hero />
			<Solutions />
			<Clients />
		</>
	)
}
