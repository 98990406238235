import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

import * as Color from "../../utils/color"

const Icon = styled.div`
	${tw`mb-4`}

	padding: .6em;
	background-color: ${Color.primary_100};
	border-radius: 50%;

	svg {
		fill: ${Color.primary};
	}
`

const Title = styled.h6`
	${tw`text-base md:text-base mb-1`}

	white-space: no-wrap;
	line-height: 1.1em;
	font-weight: bold;
	color: ${Color.primary};
`

const Description = styled.p`
	${tw`text-sm`}
`

const Card = styled.div`
	background-color: white;
	border: solid 1px lightgray;
	border-radius: 8px;
	padding: 1.4rem 1.8rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
`

const SolutionCard = ({ icon, title, description }) => {
	return (
		<Card>
			<Icon>{icon}</Icon>
			<Title>{title}</Title>
			<Description>{description}</Description>
		</Card>
	)
}

export default SolutionCard
