import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

import SolutionCard from "../../../components/SolutionCard"
import Inner from "../../../components/Inner"
import Info from "../../../components/Info"

import { mobile } from "../../../utils/breakpoint"

import { solutions } from "../../../meta/solutions"

const Solutions = styled.section``

const Content = styled.div`
	padding: 4em 0;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-columns: 1fr;

	@media ${mobile} {
		grid-template-columns: 1fr;
	}
`

const LeftBox = styled.div``

const RightBox = styled.div``

const List = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
	grid-auto-rows: 180px;
	grid-gap: 1em;

	@media ${mobile} {
		grid-template-columns: 1fr;
	}
`

const SolutionSection = () => {
	const data = useStaticQuery(graphql`
		{
			strapiHomePage {
				solutions {
					caption
					head
					detail
				}
			}
		}
	`)

	const section = data.strapiHomePage.solutions

	return (
		<Solutions>
			<Inner>
				<Content>
					<LeftBox>
						<Info subtitle={section.caption} title={section.head} detail={section.detail} />

						<List>
							{solutions.map((item, index) => {
								return <SolutionCard key={index} title={item.title} icon={item.icon} />
							})}
						</List>
					</LeftBox>
					<RightBox></RightBox>
				</Content>
			</Inner>
		</Solutions>
	)
}

export default SolutionSection
